import React, { useState } from 'react';
import { StyledBorderedToggle, StyledBorderedToggleHeader, StyledToggleContent } from './styled/StyledToggle';

const Toggle = ({ title, children, opened }) => {

  const [isOpen, toggle] = useState(opened);

  return (
    <StyledBorderedToggle>
      <StyledBorderedToggleHeader {...{ isOpen }} onClick={() => toggle(!isOpen)}>{title}</StyledBorderedToggleHeader>
      <StyledToggleContent {...{ isOpen }}>
        {children}
      </StyledToggleContent>
    </StyledBorderedToggle>
  );
};

export default Toggle;
