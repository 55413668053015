import styled, { css } from 'styled-components';

import plus from '../../../../images/plus.svg';
import minus from '../../../../images/minus.svg';

const baseToggleHeaderStyles = css`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledToggleContent = styled.div`
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
`;

export const StyledBorderedToggle = styled.div`
  border-top: 1px solid #ebe5e5;
  padding: 24px 24px 24px 0;
  
  &:last-child {
     border-bottom: 1px solid #ebe5e5;
  }
  
  ${StyledToggleContent} {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
  }
    
  h3 {
    font-weight: bold;
    letter-spacing: 0.6px;
    margin: 0;
  }
`;

export const StyledBorderedToggleHeader = styled.h3`
  ${baseToggleHeaderStyles};
  font-size: 16px;
  font-weight: 500!important;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.94;
  letter-spacing: 0.2px;
  &:after {
    content: '';
    display: block;
    min-width: 24px;
    height: 24px;
    margin-left: 24px;
    background: ${({ isOpen }) => isOpen ? `url(${minus})` : `url(${plus})`} no-repeat center;
  }
`;
