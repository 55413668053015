import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Toggle from '../../components/ui/Toggle';

const IndexPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <h2>PAYMENTS</h2>
    <Toggle title="What payment methods can I use?">
      <p>You can choose from a number of payment methods including Visa, Mastercard, Mada Debit Card and Apple Pay.</p>
    </Toggle>
    <Toggle title="Are your prices inclusive of VAT?">
      <p>Yes, all our prices and inclusive of 15% VAT</p>
    </Toggle>
    <Toggle title="My order was unsuccessful, is there a problem with it?">
      <p>We’re sorry this happened, but your order was not placed because we couldn’t process your payment details. To place a new order,
        you can check the following details to ensure it goes through:</p>
      <ul>
        <li>Check if the card details saved in your account has the correct information - this could involve anything from your name to the
          expiry date
        </li>
        <li>Make sure you’ve entered the right security code - that’s the 3-digit number on the back of your card</li>
        <li>For any other questions, you can get in touch with us at 800 440 0099</li>
      </ul>
    </Toggle>
    <h2>ORDERS</h2>
    <Toggle title="How do I know if my order has been completed successfully?">
      <p>Once you reach our Thank You page with the confirmed details of your order, it means your order was completed successfully. We’ll
        also send you an email confirming we’ve got your order</p>
    </Toggle>
    <Toggle title="How can I check the status of my order?">
      <p>Just log in to your Leem Mobile App account to review the status of your orders and other related information in the 'My Account'
        section.</p>
    </Toggle>
    <Toggle title="What qualifies as a proof of purchase for my online order?">
      <p>Once your order has been delivered or collected, you'll receive a tax invoice along with the items that will list your order and
        payment details. This can be used as a proof of purchase for any future communications.</p>
    </Toggle>
    <Toggle title="Can I cancel my order?">
      <p>Yes, but only if it’s still being processed by us. You can get in touch with us at 800 440 0099. We’re available from 9am to 6pm
        (GST), Saturday through Thursday (Closed Friday).</p>
      <p>if your order is already with our courier partner, you can make use of our free returns policy, which allows you to return your
        items within 15 days of you purchasing them.
      </p>
    </Toggle>
    <Toggle title="Why was my order cancelled?">
      <p>We’re so sorry this happened, but if your order or part of it was cancelled it could have been due to us not being able to verify
        your payment details, due to technical issues, or if the item was damaged. If you've already made the payment for your cancelled
        items, we would have automatically processed your refund. If you’re still unsure or need help, you can get in touch with us at 800 440 0099</p>
    </Toggle>
    <h2>MY ACCOUNT</h2>
    <Toggle title="How do I create an account?">
      <p>Creating an account with us is easy. You can either create an account on our Sign-Up page and click Create My Account.</p>
      <p>We will verify your contact details on sign up to protect your security and deliver best possible experience.</p>
    </Toggle>
    <Toggle title="Can I change my email address?">
      <p>Once you've created an account, select personal information and update your email address. Hit save and your new email address will
        be updated for future communication.</p>
    </Toggle>
    <Toggle title="Can I change my registered phone number?">
      <p>Once you’ve created an account, select personal information and update your phone number. You will receive a SMS to verify your new
        phone number.</p>
    </Toggle>
    <Toggle title="How do I add or edit my delivery information?">
      <p>You can sign in to your account and select Address Book in Account Overview to add new delivery information, update or delete old
        ones, as well as set a default address for your next order.</p>
    </Toggle>
    <Toggle title="What details can I view in the Order History?">
      <p>The Order History section in My Leem lets you check the status of your current order(s), access your tax invoices and print
        them.</p>
    </Toggle>
    <h2>ABOUT US</h2>
    <p>This is Leem. Modest Fashion Redefined.</p>
    <p>Since 2018, we’ve been creating sophisticated, modest collections with a moderntwist-featured in Vogue, Elle, Harpers Bazaar and Emirates Woman.</p>
    <p>Shop modest fashion at modest prices in Leem stores, across the Kingdom of Saudi Arabia, United Arab Emirates and Bahrain.</p>
    <p>Or order via the Leem App within the Kingdom of Saudi Arabia with shipping worldwide coming soon.</p>
    <p>Store Locations</p>
    <p><strong>Saudi Arabia:</strong></p>
    <p>Hyatt Mall, Riyadh</p>
    <p>Riyadh Park Mall, Riyadh</p>
    <p>Panorama Mall, Riyadh</p>
    <p>Red Sea Mall, Jeddah</p>
    <p><strong>United Arab Emirates:</strong></p>
    <p>Mercato Mall, Dubai</p>
    <p>Mirdiff City Mall, Dubai</p>
    <p><strong>Bahrain:</strong></p>
    <p>City Centre Mall, Manama</p>
  </Layout>
);

export default IndexPage
